import EnvVars from "./EnvVars";

const isDev = () => {
    return EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "prod" && EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "staging";
};

const FeatureFlags = {
    CARE_FLOWS: isDev() ? true : false,
    CARE_FLOWS_REQUIRED_FIELD_CHECK: false, // Turning off until later based on Product guidance
    STRIPS_ON_HAND: isDev() ? true : false,
};


export default FeatureFlags;
